
<template>
  <div>
    <DriveTreeView
      :folder-id="data.folderID || folderId || null"
      hide-create-folder
      hide-upload-file
      hide-setting
      download-icon-with-label
    />
  </div>
</template>

<script>
import Base from './Base.vue';
import config from '../../config';
export default {
  extends: Base,
  components: {
    DriveTreeView: () => import('../Drive/CmsTreeView.vue')
  },
  data() {
    return {
      folderId: config.driveFolderId
    };
  }
};
</script>

<style lang="scss">
</style>
